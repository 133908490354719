import styled from "styled-components"
import theme from "../../themes"

const H1 = styled.h1`
  font-size: ${theme.main.sizes.typography.mobile.h1};
  margin: 0 0 8px 0;
  color: ${props => (props.color ? props.color : "black")};
`

export default H1
