import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import H1 from "../components/H1"
import Container from "../components/Container"
import H2 from "../components/H2"
import Section from "../components/Section"
import clock from "../images/clock.svg"
import calendar from "../images/calendar.svg"
import cake from "../images/cake.svg"
import Card from "../components/Card"
import { Grid } from "@material-ui/core"
import CardLink from "../components/Card/CardLink"
import theme from "../themes/"

const BetreuungsangebotePage = () => (
  <Layout>
    <SEO title="Betreuungsangebote" />
    <Section paddingTop={theme.main.sizes.paddings.bigger}>
      <Container>
        <H1>Unsere Angebote</H1>
        <p>Wir bieten Ihnen feste sowie flexible Betreeungstage.</p>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4} style={{ marginBottom: "40px" }}>
            <Card bgColor={theme.main.colors.pinkDark} bgImage={clock}>
              <CardLink to="/flexi">
                <H2>Flexi</H2>
                <div style={{ maxWidth: "70%" }}>
                  <p>Geeignet für flexible und Teilzeit-beschäftigte Eltern.</p>
                </div>
              </CardLink>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4} style={{ marginBottom: "40px" }}>
            <Card bgColor={theme.main.colors.greenLight} bgImage={calendar}>
              <CardLink to="/standard">
                <H2>Standard</H2>
                <div style={{ maxWidth: "70%" }}>
                  <p>Geeignet für fixe Betreuungstage.</p>
                </div>
              </CardLink>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4} style={{ marginBottom: "40px" }}>
            <Card bgColor={"#7FBEC2"} bgImage={cake}>
              <CardLink to="/kontakt">
                <H2>Raumvermietung</H2>
                <div style={{ maxWidth: "70%" }}>
                  <p>Für Kinderanlässe auf Anfrage möglich.</p>
                </div>
              </CardLink>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Section>
  </Layout>
)

export default BetreuungsangebotePage
