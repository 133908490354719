import styled from "styled-components"
import theme from "../../themes"
import { device } from "../../themes/device"

const Card = styled.div`
  color: ${props =>
    props.bgColor === theme.main.colors.white
      ? theme.main.colors.black
      : theme.main.colors.white};
  text-align: ${props => (props.textAlign ? props.textAlign : "left")};
  background: ${props =>
    props.bgColor ? props.bgColor : theme.main.colors.pink};
  background-image: ${props =>
    props.bgImage ? `url(${props.bgImage})` : "none"};
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 96px;
  padding: ${props => (props.padding ? props.padding : "16px")};
  height: 100%;
  border-radius: 8px;
  position: relative;
  margin-bottom: 40px;

  @media ${device.tablet} {
    margin-bottom: 0;
  }
`

export default Card
