import styled from "styled-components"

const Section = styled.section`
  position: relative;
  padding-top: ${props => (props.paddingTop ? props.paddingTop : "0")};
  margin-bottom: ${props =>
    props.marginBottom ? props.marginBottom : "140px"};
`

export default Section
